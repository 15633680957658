import React from 'react'
import './Blog.css';

export default function Blog() {
  return (
       <div id='blog'>
            <h1 style={{textAlign:"center"}}>My Blogs</h1>
    <div className='blog'>
<h3>Are You Interested?</h3>
<h1>Coming Soon....</h1>

    </div>
    </div>
  )
}
